.dpag-header {
    border-bottom: 1px solid #e5cb60;
    background: #fc0;
    background: linear-gradient(to bottom, #ffe680 0, #fc0 100%);
    height: 58px;
    @include media-breakpoint-up(md) {
        margin-top: 1rem;
        height: 64px;
    }

    .dpag-logo {
        height: 57px;
        width: 57px;

        #icon {
            enable-background: new 0 0 93 90;
        }

        #icon .logo-lasche,
        #icon .logo-gradient,
        #icon .logo-shadow {
            display: none;
        }

        #icon .st0 {
            fill: #FFFFFF;
        }

        @include media-breakpoint-up(md) {
            margin-top: -8px;
            width: 62px;
            height: 60px;

            #icon .logo-lasche,
            #icon .logo-gradient,
            #icon .logo-shadow {
                display: block;
            }
        }
    }
}

.dpag-subheader {
    display: none;
    // display dpag-subheader only on larger devices
    @include media-breakpoint-up(md) {
        display: block;
        .navbar {
            height: 65px;
        }
        .dpag-product-logo img {
            max-width: 100%;
            height: 16px;
        }
        .dpag-principal-logo img {
            max-width: 100%;
            height: 56px;
            
        }
    }
}