/*! Deutsche Post Bootstrap theme */

// Variables override

// Color system
$yellow: #ffcc00;
$primary: $yellow;
$red: #bd1111;
$green: #9ebd43;
$gray-800: #505050;
$gray-500: #878787;
$secondary: #ededed;

$body-color: #333;

$link-color: $body-color;
$link-hover-color: $body-color;

$btn-transition: color .25s ease-in-out, background-color .25s ease-in-out, border-color .25s ease-in-out, box-shadow .25s ease-in-out !default;

// Typography
@import "./dpag-font";

// Default bootstrap styles
@import "node_modules/bootstrap/scss/bootstrap";

// Additional theme customization
@import "./dpag-header";
@import "./dpag-footer";
@import "./dpag-forms";

// shame.css for dirty hacks
@import "./shame";