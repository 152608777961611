.dpag-footer {
    color: #555;

    a {
        color: #555;
    }

    .navbar-expand .navbar-nav {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .navbar-item {
        display: inline-block;
        padding-right: 4px;
    }
    .navbar-expand .navbar-nav .navbar-item:after {
        margin-left: 2px;
        content: " | ";
        white-space: pre;
    }

    .navbar-expand .navbar-nav .navbar-item:last-child:after {
        content: "";
    }
}
