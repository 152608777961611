@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 200;
  src: url(fonts/Delivery_W_Lt.woff2) format("woff2"), url(fonts/Delivery_W_Lt.woff) format("woff"); }

@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 200;
  src: url(fonts/Delivery_W_LtIt.woff2) format("woff2"), url(fonts/Delivery_W_LtIt.woff) format("woff"); }

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/Delivery_W_Rg.woff2) format("woff2"), url(fonts/Delivery_W_Rg.woff) format("woff"); }

@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 400;
  src: url(fonts/Delivery_W_It.woff2) format("woff2"), url(fonts/Delivery_W_It.woff) format("woff"); }

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/Delivery_W_Bd.woff2) format("woff2"), url(fonts/Delivery_W_Bd.woff) format("woff"); }

@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 700;
  src: url(fonts/Delivery_W_BdIt.woff2) format("woff2"), url(fonts/Delivery_W_BdIt.woff) format("woff"); }

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 200;
  font-stretch: condensed;
  src: url(fonts/Delivery_W_CdLt.woff2) format("woff2"), url(fonts/Delivery_W_CdLt.woff) format("woff"); }

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 800;
  font-stretch: condensed;
  src: url(fonts/Delivery_W_CdBlk.woff2) format("woff2"), url(fonts/Delivery_W_CdBlk.woff) format("woff"); }

$font-family-base: "Delivery", sans-serif;
$font-size-base: 1rem; // 16px
$headings-font-weight: 400 !default;

body {
  font-style: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
}
