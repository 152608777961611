// dpag primary-button
.btn-primary:hover {
    color: $white;
    background-color: $gray-800;
    border-color: $gray-800;
}

// decrease margin below form labels
label {
    margin-bottom: 0.25rem;
}

// don't show icons for invalid select items in validated forms
form.was-validated select.form-control:invalid, select.form-control.is-invalid {
    background-image: none;
}

// don't show icons for valid select items in validated forms
form.was-validated select.form-control:valid, select.form-control.is-valid {
    background-image: none;
}

// adjust invalid icon position in validated forms
.form-control.is-invalid, .was-validated .form-control:invalid {
    background-position: 99% calc(.375em + .1875rem);
}

// adjust valid icon position in validated forms
.form-control.is-valid, .was-validated .form-control:valid {
    background-position: 99% calc(.375em + .1875rem);
}