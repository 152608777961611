/**
 * shame.css
 * https://csswizardry.com/2013/04/shame-css/
 */

// keep the main content in shape
main {
    min-height: 450px;
}

p a {
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom-color: currentColor;
    transition: 0.2s;
    transition-property: color, border-bottom-color;
}

p a:hover {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
}

.navbar-brand {
    font-weight: 800;
    color: #000;
}

.btn-outline-primary {
    color: #000;
    border-color: #000;
}
.btn-outline-secondary {
    color: #212529;
    border-color: #212529;
}

legend, label {
    font-weight: 400;
}

.custom-control-label {
    font-weight: normal;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $green;
    background-color: $green;
}

.form-check-label {
    font-weight: normal;
}

// add shadow to card component (adopted from DHL)
.card {
    border: 1px solid transparent;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.10), 0 2px 12px 0 rgba(0,0,0,0.10), 0 1px 2px 0 rgba(0,0,0,0.10);
}

a.card:hover {
    border: 1px solid transparent;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
}

// add shadow to modal component (adopted from DHL)
.modal-content {
    border: 1px solid transparent;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.10), 0 2px 12px 0 rgba(0,0,0,0.10), 0 1px 2px 0 rgba(0,0,0,0.10);
}

.jumbotron {
    background-color :#ebebeb;
}